import Footer from './Footer';
import Header from './Header';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />

      <main id="main" className="mt-[56px] lg:mt-[118px]">
        {children}
      </main>

      <Footer />
    </>
  );
}

export default Layout;
