import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

type AccessibilityOptionsProps = {
  variant?: 'column' | 'row';
  className?: string;
};

function AccessibilitySettings({
  variant = 'row',
  className,
}: AccessibilityOptionsProps) {
  const {
    useOpenDyslexicFont,
    handleUseDyslexicFontChange,
    fontSize,
    handleFontSizeChange,
    useHighContrast,
    handleUseHighContrastChange,
  } = useAccessibilitySettings();

  const getRadioButtonClasses = (checked: boolean) => {
    return twMerge(
      'btn btn-sm font-normal h-[34px]',
      useHighContrast ? 'btn-contrast-yellow' : 'btn-primary-alt',
      checked && useHighContrast
        ? 'border-black hover:border-black border-2'
        : null,
      checked && !useHighContrast
        ? 'border-white hover:border-white border-2'
        : null
    );
  };

  return (
    <div
      className={twMerge(
        'flex items-center',
        className,
        variant === 'column' ? 'flex-col space-y-2 items-stretch' : null
      )}
    >
      <button
        type="button"
        className={twMerge(
          'btn btn-sm',
          useOpenDyslexicFont ? 'font-sans' : 'font-opendyslexic',
          useHighContrast ? 'btn-contrast-yellow' : 'btn-primary-alt'
        )}
        onClick={() => handleUseDyslexicFontChange(!useOpenDyslexicFont)}
        aria-label="toggle between regular and opendyslexic fonts"
      >
        {useOpenDyslexicFont ? 'Regular Font' : 'OpenDyslexic Font'}
      </button>

      <div
        className={twMerge(
          'flex items-center ml-3 space-x-2',
          variant === 'column' ? 'self-center' : null
        )}
      >
        <p className="text-inherit">Font size</p>
        <RadioGroup
          value={fontSize}
          onChange={value => handleFontSizeChange(value)}
          className="flex space-x-2"
          aria-label="choose font size"
        >
          <RadioGroup.Option value="default">
            {({ checked }) => (
              <div
                className={twMerge(getRadioButtonClasses(checked), 'text-sm')}
              >
                <span className="sr-only">default font size</span>
                <span className="" aria-hidden="true">
                  A
                </span>
              </div>
            )}
          </RadioGroup.Option>

          <RadioGroup.Option value="large">
            {({ checked }) => (
              <div
                className={twMerge(getRadioButtonClasses(checked), 'text-base')}
              >
                <span className="sr-only">large font size</span>
                <span className="" aria-hidden="true">
                  A
                </span>
              </div>
            )}
          </RadioGroup.Option>

          <RadioGroup.Option value="extra-large">
            {({ checked }) => (
              <div
                className={twMerge(getRadioButtonClasses(checked), 'text-lg')}
              >
                <span className="sr-only">extra large font size</span>
                <span className="" aria-hidden="true">
                  A
                </span>
              </div>
            )}
          </RadioGroup.Option>
        </RadioGroup>

        <button
          type="button"
          className={twMerge(
            'btn btn-sm font-normal text-lg h-[34px]',
            useHighContrast ? 'btn-primary-alt' : 'btn-contrast-yellow'
          )}
          onClick={() => handleUseHighContrastChange(!useHighContrast)}
          aria-label="toggle between regular and high contrast mode"
        >
          <span className="sr-only">high contrast mode</span>
          <span aria-hidden="true">A</span>
        </button>
      </div>
    </div>
  );
}

export default AccessibilitySettings;
