import { FaBook, FaEnvelope, FaHome, FaQuestionCircle } from 'react-icons/fa';
import { MdRestaurant } from 'react-icons/md';

import { FooterColumnItem, NavItem } from '@/types/index';

export const navItems: NavItem[] = [
  {
    icon: <FaHome className="mr-2" aria-hidden="true" />,
    label: 'Home',
    href: '/',
  },
  {
    icon: <FaQuestionCircle className="mr-2" aria-hidden="true" />,
    label: 'How to use',
    href: '/how-to-use',
  },
  {
    icon: <MdRestaurant className="mr-2" aria-hidden="true" />,
    label: 'Restaurants',
    href: '/restaurants',
  },
  {
    icon: <FaBook className="mr-2" aria-hidden="true" />,
    label: 'Our Story',
    href: '/our-story',
  },
  {
    icon: <FaEnvelope className="mr-2" aria-hidden="true" />,
    label: 'Contact Us',
    href: '/contact-us',
  },
];

export const footerColumns: FooterColumnItem[] = [
  {
    title: 'Navigation',
    items: navItems,
  },
  {
    title: 'Restaurants',
    items: [
      {
        label: 'Chains and restaurants',
        href: '/restaurants',
      },
      {
        label: 'Request Restaurant',
        href: '/request-restaurant',
      },
    ],
  },
  {
    title: 'Restaurant Owners',
    items: [
      {
        label: 'Benefits',
        href: '/benefits',
      },
    ],
  },
  {
    title: 'Company',
    items: [
      {
        label: 'Contact Us',
        href: '/contact-us',
      },
    ],
  },
];
